<template>
    <div class="modal-wrapper">
        <div class="modal"> 
            <div class="looking-animation">
                <Vue3Lottie :animationData="lookingAnimation"/>
                <Vue3Lottie class="animation2" :animationData="lookingAnimation2"/>
            </div>

            <div class="modal-title modal-title-sm">
                <p>Wir suchen!</p>
            </div>
 
            <div class="modal-msg"> 
                <p>
                    Wir suchen nach Unternehmen, die Ihre Daten speichern.
                </p>
            </div>

            
            <!-- <button class="modal-btn modal-btn-sm">
                <p>Zu Verbindungen gehen</p>
            </button>  -->

            <router-link to="/searchForData" class="modal-btn modal-btn-sm">
                <p>Zu Verbindungen gehen</p>
            </router-link>
        </div>
    </div>
</template>
<script>
import lookingAnimation from '../../assets/animations/looking-for.json'
import lookingAnimation2 from '../../assets/animations/looking-for2.json'


export default {
    data(){
        return{
            lookingAnimation,
            lookingAnimation2
        }
    }
}
</script>
<style scoped lang="scss">
@import "../../assets/modals.scss";

 
.looking-animation{
    width: 298px;
    height: 298px;
    margin: 0 auto; 
    margin-top: -80px;
    position: relative; 
} 

.animation2{ 
    position: absolute;
    top: -30px;
    left: 90px;
}

.modal-btn{
    width: 228px;
}

/* imac 24" */
/* 2048 x 1152 */
@media (min-width: 2048px) {
}

/* 2304-1470 */
@media (min-width: 2304px) {
}

/* 2470 - 1328 */
@media (min-width: 2470px) {
    .modal-btn {
        width: 260px;
    }
}

/* imac retina */
/*2560-1440*/
@media (min-width: 2560px) {
    .modal-btn {
        width: 280px;
    }
}

/*2573-1206*/
@media(min-width: 2573px) {
}

/* 2880-1670 */
@media(min-width: 2880px) {
    .modal-btn {
        width: 350px;
    }

    .looking-animation{
        width: 320px;
        height: 320px;
    } 
}

/* 3000-1870 */
@media(min-width: 3000px) {
}

/*3200-1800*/
@media(min-width: 3200px) {
    .modal-btn {
        width: 380px;
    }

    .looking-animation{
        width: 350px;
        height: 350px;
    } 
}

/*3360-1890 1695*/
@media(min-width:3360px) {
}

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px){
    .modal-btn {
        width: 430px;
    }

    .looking-animation{
        width: 400px;
        height: 400px; 
    } 
}

/* 1920x961 (current) */

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {
    .looking-animation{
        width: 280px;
        height: 280px; 
    } 
}

/*1688-1080*/
@media(max-width: 1688px) {
}

/*1680-1080*/
@media(max-width: 1680px) {
    .looking-animation{
        width: 260px;
        height: 260px; 
    } 
}

/*1640-1080*/
@media(max-width: 1640px) {
}

/* 1600 - 757 */
@media (max-width: 1600px) {
    .looking-animation{
        width: 240px;
        height: 240px; 
    } 
}

/*1536-864*/
@media(max-width:1536px) {
}

/* macbook pro/air */
/* 1440-900 */
@media(max-width:1440px) {
    .looking-animation{
        width: 220px;
        height: 220px; 
    } 

    .animation2 { 
        left: 70px;
        width: 80% !important;
    }
}

/*1366-638*/
@media (max-width:1366px) {
}

/* macbook air */
/* 1280-800 */
@media (max-width:1280px) {
}

/* 1200-1920 */
@media (max-width:1200px) {
}

/*1134x712*/
@media(max-width:1134px) {
}


/* tablets */

/* micro duo */
/*1114x705*/
@media(max-width:1114px) {
}

/*1024-768*/
/* ipad pro */
/*1024-1366*/
@media (max-width:1024px) {
    .looking-animation{
        width: 200px;
        height: 200px; 
    } 

    .animation2 {  
        width: 60% !important;
        left: 100px;
    }
}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {
}

/* 962x601 */
@media(max-width: 962px) {
    .looking-animation{
        width: 180px;
        height: 180px;  
        margin-top: -60px; 
    } 

    .animation2{  
        left: 90px;
    }

    .modal {
        padding: 45px 25px;
    }
}

/* 900x1600 */
@media(max-width: 900px) {
}

/* galaxy fold */
/* 884 x 1104 */
@media(max-width: 884px) {
}

/* ipad pro 2022 */
/*834-1136*/
@media(max-width: 834px) {
}

/* ipad air 4 */
/* 820x1180 */
@media(max-width: 820px) {
}

/* ipad 2020 */
/* 810x1080 */
@media(max-width: 810px) {
}

/* galaxy tab 7 */
/* 800x1280 */
@media(max-width: 800px) {
}

/*778-304*/
@media(max-width: 778px) {}

/*775*/
@media(max-width: 775px) {}

/* ipad air/mini */
/* 768x1024 */
@media(max-width: 768px) {
    .looking-animation{
        width: 170px;
        height: 170px;  
        margin-top: -50px; 
    } 

    .animation2{  
        left: 80px;
    }

    .modal {
        padding: 45px 20px;
    }
}

/*712-1138*/
@media(max-width:712px) {
}

/* ipad air 2/3 */
/* 694-768 */
@media(max-width:694px) {
}

/* ipad pro 1/2 */
/* 678-1024 */
@media(max-width:678px) {
}

/* 600x962 */
@media(max-width:600px) {
}

/* 577-951 */
@media(max-width:577px) {    
}

/* 540-960 */
@media(max-width:540px) {
   
}


/* mobiles */

/* s-note */
/*480-853*/
@media (max-width:480px) {
}

/* ipad air 2/3 */
/*438-1024*/
@media(max-width:438px) {
}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px){
}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px){
}

/* ip11 pro max */
/* 414-896/736*/
@media (max-width:414px) {
}

/* s21 ultra, pix6 */
/* 412-915*/
/* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
@media (max-width:412px) { 
}

/* ip14 pro, pix5 */
/*393 x 852*/
@media(max-width:393px) {
    .looking-animation{
        width: 150px;
        height: 150px;  
        margin-top: -40px; 
    } 

    .animation2{  
        left: 70px;
    }
    
    .modal {
        padding: 45px 10px;
    }
}

/* ip13 pro */
/*390 x 844*/
@media(max-width:390px) {
}

/* s21 plux */
/*384-854*/
@media(max-width:384px) {
}

/* ip X */
/*375-812/667*/
@media(max-width:375px) {
}

/* s22 ultra */
/*360x772*/
@media(max-width:360px) {
}

/* ip SE */
/*320x568*/
@media(max-width:320px) {
}
</style>