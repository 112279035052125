<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  mounted() {
    // if (this.$store.getters['darkMode/isDarkMode']) {
    //   document.body.classList.add('dark-mode');
    // }

    const isDarkMode = this.$store.getters['darkMode/isDarkMode'];
    document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }
};
</script>

<style lang="scss">
@import './assets/styles.css';
@import 'swiper/css';
@import 'swiper/css/pagination';
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.swiper-pagination{
        display: none !important;
    }

    @media(max-width: 768px) {
      .swiper{
        padding-bottom: 50px !important;
      }
      .swiper-pagination{
        display: initial !important; 
      }
      .swiper-pagination-bullet-active{
        background:#FF3A67 !important;
      }

      .swiper-pagination-bullet{
        background:hsla(346, 100%, 61%, 1) !important;
      }

      .swiper-pagination-bullet{
        width: 12px !important;
        height: 12px !important;
      }
    }

    @media(max-width: 600px) {
      .partners-section{
        .swiper{
          .swiper-wrapper{
              margin-left: -50px !important;
          }
        }
      }
    }
</style>