<template>
    <div class="modal-wrapper">
 
        <div class="modal modal-lg"> 
            <div class="close-modal-btn">
                <img src="../../assets/icons/close_icon.svg"/>
            </div>
            
            <div class="identity-about">
                <div class="modal-title modal-title-sm">
                    <p>Bevor Sie fortfahren…</p>
                </div>
                <div class="identity-about-content">
                    <p>
                        Autorisierungsformular Datum: 21. März 2024 um 00:07 Uhr Wohnhaft in F-15, Hillsborough, Tampa, 33501, FL, USA, E-Mail-Adresse
                    </p>


                    <p ref="identityText" :style="maxHeightStyle">
                        Hiermit bevollmächtige ich Incogni Inc., mit eingetragener Adresse bei (der ‘Bevollmächtigte’), als mein autorisierter Vertreter zu handeln und alle rechtmäßigen Maßnahmen zu ergreifen, die erforderlich sind, um in meinem Namen meine folgenden Rechte gemäß der geltenden Datenschutzgesetzgebung auszuüben, einschließlich (aber nicht beschränkt auf) des California Consumer Privacy Act (‘CCPA’) und des California Privacy Rights Act (‘CPRA’) (Datenschutzgesetze) und Anfragen zur Umsetzung dieser Rechte an alle natürlichen und juristischen Personen zu richten, die den Datenschutzgesetzen unterliegen: Die Löschung meiner persönlichen Daten (Informationen) zu verlangen; Jede Einwilligung, die ich zur Verarbeitung meiner persönlichen Daten (Informationen) gegeben habe, zu widerrufen; Der Verarbeitung meiner persönlichen Daten (Informationen), einschließlich, aber nicht beschränkt auf, Profiling und Direktmarketing, zu widersprechen. Ich stimme zu und erkenne an, dass: der Bevollmächtigte nach eigenem Ermessen von dieser eingeschränkten Vertretung zurücktreten kann; dieses Autorisierungsformular automatisch gegenüber jeder einzelnen natürlichen oder juristischen Person endet, gegen die meine Rechte gemäß den Datenschutzgesetzen geltend gemacht werden.
                    </p>
                </div>

                <div class="show-more">
                    <a @click="showMoreInfo = !showMoreInfo"> 
                        {{ showMoreInfo ? 'Weniger anzeigen' : 'Mehr anzeigen' }}
                    </a>  
                </div>
                
            </div>


            <div class="identity-input-wrapper">
                <div class="identity-input-title">
                    <p>Laden Sie hier Ihren Ausweis hoch</p>
                </div>

                 <div class="identity-animation">
                    <Vue3Lottie :animationData="cardAnimation"/>
                 </div>

                <div class="identity-input-row">
                    <div class="identity-input front-card" @click="this.$refs.frontIdImg.click()">
                        <div  class="identity-card-img">
                            <img src="../../assets/icons/front_id_icon.svg"/>
                        </div>
                        
                        <p>Vorderseite</p>

                        <input
                            type="file"
                            id="frontImgInput"
                            ref="frontIdImg"
                            style="display: none"
                            accept="image/*"
                            @change="onFrontImgInput"
                        />
                    </div>
                    <div class="identity-input back-card" @click="this.$refs.backIdImg.click();">
                        <div  class="identity-card-img">
                            <img src="../../assets/icons/back_id_icon.svg"/>
                        </div>
                        <p>Rückseite</p>

                        <input
                            type="file"
                            id="backImgInput"
                            ref="backIdImg"
                            style="display: none"
                            accept="image/*"
                            @change="onBackImgInput"
                        />
                    </div>
                </div>
                

                <div class="identity-input-btns">
                    <button  class="modal-btn modal-btn-sm modal-btn-white">
                        <p>Zurück</p>
                    </button>

                    <!-- <button class="modal-btn modal-btn-sm">
                        <p>Weiter</p>
                    </button> -->

                    <router-link to="/documentsChecking" class="modal-btn modal-btn-sm">
                        <p>Weiter</p>
                    </router-link>
                </div> 
            </div> 
        </div>
    </div>
</template>
<script>

import cardAnimation from '../../assets/animations/id-card-animation.json'


export default {
    data(){
        return{
            hasSignature: false,

            signatureData: null,

            showMoreInfo: false,
            fullHeight: 0,

            cardAnimation,

            frontImageUrl: null,

            backImageUrl: null,
        }
    },
    mounted() {
        this.fullHeight = this.$refs.identityText.scrollHeight;
    },
    computed: {
        maxHeightStyle() {
        return {
            maxHeight: this.showMoreInfo ? `${this.fullHeight}px` : '22.5em',   
            transition: 'max-height 0.3s ease',
            overflow: 'hidden',
        };
        },
    },
    methods:{
        onFrontImgInput(event) {
            const file = event.target.files[0];
            if (file && file.type.startsWith("image/")) {
                const reader = new FileReader();
                reader.onload = (e) => {
                this.frontImageUrl = e.target.result;
                };
                reader.readAsDataURL(file);
            } else {
                this.frontImageUrl = null;
            }
        },

        onBackImgInput(event) {
            const file = event.target.files[0];
            if (file && file.type.startsWith("image/")) {
                const reader = new FileReader();
                reader.onload = (e) => {
                this.backImageUrl = e.target.result;
                };
                reader.readAsDataURL(file);
            } else {
                this.backImageUrl = null;
            }
        },


    }
}
</script>
<style scoped lang="scss">
@import "../../assets/modals.scss"; 
 

.modal-lg{ 
    display: flex;
    justify-content: space-between;
    align-items: start;
    position: relative;
}

.identity-about{
    width: 45%;
}

.identity-input-wrapper{
    padding-top: 15px;
    width: 49%;
}

.modal-title{
    text-align: left;
    margin-bottom: 11px;
}

.identity-about-content{
    margin-bottom: 24px;
    p{
        color: #666666;
        font-size: 14px;
        transition: max-height 0.5s ease;
        overflow: hidden;

        &:first-child{
            margin-bottom: 30px;
        }
    }  
}

.show-more{
    a{
        cursor: pointer;
        color: #FF3A67;
        font-size: 14px;
    }
}



.identity-input-title{
    p{
        color: #000000;
        font-size: 20px; 
    }
    margin-bottom: 15px;
}

.identity-animation{
    width: 213px;
    height: 213px;
    margin: 0 auto;
}

.identity-input-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;

    .identity-input{
        width: 45%;  
        border: 1px dashed #707070;
        border-radius: 20px; 
        padding: 8px 0;
        text-align: center;
        cursor: pointer;

        p{
            font-size: 12px;
            color: #000000;
            margin-top: 3px;
        }
    }

    .identity-card-img{
        width: 38px;
        height: 39px;
        margin: 0 auto;

        img{
            width: 100%;
        }
    }
}


.identity-input-btns{
    display: flex;
    align-items: center;
    justify-content: space-between; 

    .modal-btn{   
        margin: initial;    
    }
} 
</style>