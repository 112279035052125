<template>
    <div class="modal-wrapper">
        <div class="modal modal-sm">
            <div class="modal-title modal-title-lg">
                <p >Erstelle ein neues Konto</p>
            </div> 

            <div class="modal-msg modal-msg-blue">
                <p >Gib deine Daten ein, um dein Profil zu vervollständigen.</p>
            </div>

            <div class="input-wrapper">
                <input type="text" placeholder="Adresse">
            </div>

            <div class="inputs-row">
                <div class="input-wrapper m-input">
                    <input type="text" placeholder="Ort">
                </div>


                <div class="input-wrapper m-input">
                    <input type="text" placeholder="PLZ">
                </div>
            </div>

            <div class="input-wrapper">
                <input type="email" placeholder="Land">
            </div>

            <div class="inputs-row">
                <div class="input-wrapper sm-input">
                    <input type="email" placeholder="+41">
                </div>

                <div class="input-wrapper xl-input">
                    <input type="email" placeholder="Telefonnummer">
                </div>
            </div>
 
            <!-- <button class="modal-btn">
                <p>Weiter</p>
            </button> -->

            <router-link to="/newSignature" class="modal-btn">
                <p>Weiter</p>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped lang="scss">
@import "../../assets/modals.scss"; 
  
.inputs-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    .input-wrapper{
        margin-bottom: 0;
    }

    .m-input{
        width: 48%;
    }

    .sm-input{
        width: 20%;
    }

    .xl-input{
        width: 76%;
    }

}
 
.modal-btn{ 
    margin-top: 55px;  
} 


/* imac 24" */
/* 2048 x 1152 */
@media (min-width: 2048px) {
}

/* 2304-1470 */
@media (min-width: 2304px) {
}

/* 2470 - 1328 */
@media (min-width: 2470px) {
}

/* imac retina */
/*2560-1440*/
@media (min-width: 2560px) {
}

/*2573-1206*/
@media(min-width: 2573px) {
}

/* 2880-1670 */
@media(min-width: 2880px) {
}

/* 3000-1870 */
@media(min-width: 3000px) {
}

/*3200-1800*/
@media(min-width: 3200px) {
}

/*3360-1890 1695*/
@media(min-width:3360px) {
}

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px){
}

/* 1920x961 (current) */

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {
}

/*1688-1080*/
@media(max-width: 1688px) {
}

/*1680-1080*/
@media(max-width: 1680px) {
}

/*1640-1080*/
@media(max-width: 1640px) {
}

/* 1600 - 757 */
@media (max-width: 1600px) {
}

/*1536-864*/
@media(max-width:1536px) {
}

/* macbook pro/air */
/* 1440-900 */
@media(max-width:1440px) {
}

/*1366-638*/
@media (max-width:1366px) {
}

/* macbook air */
/* 1280-800 */
@media (max-width:1280px) {
}

/* 1200-1920 */
@media (max-width:1200px) {
}

/*1134x712*/
@media(max-width:1134px) {
}


/* tablets */

/* micro duo */
/*1114x705*/
@media(max-width:1114px) {
}

/*1024-768*/
/* ipad pro */
/*1024-1366*/
@media (max-width:1024px) {
}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {
}

/* 962x601 */
@media(max-width: 962px) {
}

/* 900x1600 */
@media(max-width: 900px) {
}

/* galaxy fold */
/* 884 x 1104 */
@media(max-width: 884px) {
}

/* ipad pro 2022 */
/*834-1136*/
@media(max-width: 834px) {
}

/* ipad air 4 */
/* 820x1180 */
@media(max-width: 820px) {
}

/* ipad 2020 */
/* 810x1080 */
@media(max-width: 810px) {
}

/* galaxy tab 7 */
/* 800x1280 */
@media(max-width: 800px) {
}

/*778-304*/
@media(max-width: 778px) {}

/*775*/
@media(max-width: 775px) {}

/* ipad air/mini */
/* 768x1024 */
@media(max-width: 768px) {
}

/*712-1138*/
@media(max-width:712px) {
}

/* ipad air 2/3 */
/* 694-768 */
@media(max-width:694px) {
}

/* ipad pro 1/2 */
/* 678-1024 */
@media(max-width:678px) {
}

/* 600x962 */
@media(max-width:600px) {
}

/* 577-951 */
@media(max-width:577px) {    
}

/* 540-960 */
@media(max-width:540px) {
}


/* mobiles */

/* s-note */
/*480-853*/
@media (max-width:480px) {
}

/* ipad air 2/3 */
/*438-1024*/
@media(max-width:438px) {
    .inputs-row{
        .sm-input{
            width: 30%;
        }

        .xl-input{
            width: 66%;
        }
    }
}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px){
}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px){
}

/* ip11 pro max */
/* 414-896/736*/
@media (max-width:414px) {

}

/* s21 ultra, pix6 */
/* 412-915*/
/* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
@media (max-width:412px) { 
}

/* ip14 pro, pix5 */
/*393 x 852*/
@media(max-width:393px) {}

/* ip13 pro */
/*390 x 844*/
@media(max-width:390px) {
}

/* s21 plux */
/*384-854*/
@media(max-width:384px) {
}

/* ip X */
/*375-812/667*/
@media(max-width:375px) {
}

/* s22 ultra */
/*360x772*/
@media(max-width:360px) {
}

/* ip SE */
/*320x568*/
@media(max-width:320px) {
}
</style>