// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
// import Home2 from '../views/Home2.vue';
import Dashboard from '@/views/dashboard/Dashboard.vue';
import Profile from '@/views/dashboard/Profile.vue';
import RequestCards from '@/views/dashboard/DashboardRequests.vue';
import DetailsView from '@/views/dashboard/DashboardDetails.vue';
import Subscription from '@/views/dashboard/Subscription.vue';


import Login from '../components/login/Login.vue';


import Register from '../components/register/NewRegister.vue';
import GoConfirm from '../components/register/GoConfirm.vue';
import SetPassword from '../components/register/SetPassword.vue';
import NextPhase from '../components/register/NextPhase.vue';


import NewSignature from '../components/verification/NewSignature.vue';
import NewId from '../components/verification/NewId.vue';
import DocumentsChecking from '../components/verification/DocumentsChecking.vue';
import FailedVerification from '../components/verification/FailedVerification.vue';


import LookingFor from '../components/dashboard/LookingFor.vue';
import OrderSuccess from '../components/dashboard/OrderSuccess.vue';
import SearchForData from '../components/dashboard/SearchForData.vue';

const routes = [
  {
    path: '/lookingFor',
    name: 'LookingFor',
    component: LookingFor
  },

  {
    path: '/orderSuccess',
    name: 'OrderSuccess',
    component: OrderSuccess
  },

  {
    path: '/searchForData',
    name: 'SearchForData',
    component: SearchForData
  },

  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/home2',
  //   name: 'Home2',
  //   component: Home2
  // },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },

  {
    path: '/newSignature',
    name: 'NewSignature',
    component: NewSignature
  },

  {
    path: '/newId',
    name: 'NewId',
    component: NewId
  }, 

  {
    path: '/failedVerification',
    name: 'FailedVerification',
    component: FailedVerification
  }, 

  {
    path: '/documentsChecking',
    name: 'DocumentsChecking',
    component: DocumentsChecking
  }, 

  {
    path: '/goConfirm',
    name: 'GoConfirm',
    component: GoConfirm
  },

  
  {
    path: '/setPassword',
    name: 'SetPassword',
    component: SetPassword
  },

  {
    path: '/nextPhase',
    name: 'NextPhase',
    component: NextPhase
  },

  {
    path: '/dashboard',
    component: Dashboard,
    children: [
      {
        path: 'requests',
        component: RequestCards,
      },
      {
        path: 'details',
        component: DetailsView,
      },
      {
        path: 'subscription',
        component: Subscription,
      },
      {
        path: 'profile',
        component: Profile,
      },
      {
        path: '',
        redirect: '/dashboard/requests', 
      },
    ],
  },
 
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
