<template>
    <div class="modal-wrapper">
        <div class="modal modal-sm"> 
            <div class="modal-title modal-title-lg">
                <p>Erstelle dein Passwort</p>
            </div>

            <div class="modal-msg modal-msg-blue">
                <p>
                    Erstelle dein Passwort mit mindestens 10 Zeichen und einer Zahl, um dein Konto optimal zu schützen.
                </p>
            </div>

            <div class="input-wrapper password">
                <input type="password" placeholder="Passwort">
            </div>

            <div class="input-wrapper confirm-pass">
                <input type="password" placeholder="Passwort wiederholen">
            </div>

<!--     
            <button class="modal-btn">
                <p>Weiter</p>
            </button> -->

            
            <router-link to="/nextPhase" class="modal-btn">
                <p>Weiter</p>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped lang="scss">
@import "../../assets/modals.scss"; 
 
.confirm-pass{ 
    margin-bottom: 35px;
}
</style>