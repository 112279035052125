<template>
<div>
    <nav>
        <div class="nav-logo">
            <img src="../assets/cleandata_logo.svg" />
        </div>

        <div class="nav-links" v-if="!mobile">
            <a>Services</a>
            <a>Resume</a>
            <a>Pricing</a>
            <a>Contact</a>
        </div>

        <div class="nav-btns" v-if="!mobile">  
            <router-link to="/register" class="default-btn default-login-btn">EINLOGGEN</router-link>
            <router-link to="/register" class="default-btn default-started-btn">JETZT STARTEN</router-link>
        </div>

        <div v-if="mobile" @click="toggleMobileMenu()">
            <p v-if="mobMenu">open</p>
            <p v-else>close</p>
        </div>


    </nav>

    <div v-if="mobile" class="mobile-menu" :class="{ 'mobile-menu-opened': slideMobMenu }">
        links

        <div @click="toggleMobileMenu()">close</div>
    </div>

    <div class="get-started-section">
        <div class="column big-column">
            <div class="big-text">
                <p>Übernimm die Kontrolle über deine persönlichen Daten und stoppe Unternehmen, sie auszunutzen.</p>
            </div>

            <div class="small-text">
                <p>Unsere sichere, DSGVO-konforme und benutzerfreundliche Lösung gibt<br> Ihnen die volle Kontrolle über Ihre Daten zurück, indem sie Ihre<br> persönlichen Informationen automatisch aus Datenbanken von<br> Unternehmen entfernt.</p>
            </div>

            <router-link to="/register" class="default-btn default-started-btn">JETZT STARTEN</router-link>
        </div>
        <div class="column">
            <div class="column-img">
                <img src="../assets/imgs/banner-img.png" />
            </div>
        </div>
    </div>

    <div class="partners-section">
        <!--  -->
        <swiper 
            :slides-per-view="5" 
            :space-between="150" 
            :loop="true" 
            :modules="modules"  
            :speed="1000" 
            :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
            } "
            :breakpoints=" {
                
                // when window width is >= 320px
                // 320: {
                //     slidesPerView: 2,
                //     spaceBetween: 20
                // },
                // when window width is >= 480px
                982: {
                    slidesPerView: 5 ,
                    spaceBetween: 150 , 
                },
                801: {
                    slidesPerView: 4,
                    spaceBetween: 100 , 
                },
                601: {
                    slidesPerView: 3,
                    spaceBetween: 100, 
                },
                0: {
                    slidesPerView: 2.5,
                    spaceBetween: 50, 
                },
                // when window width is >= 640px
          
            }"
       >
            <swiper-slide class="partner"><img src="../assets/google-logo.svg" /></swiper-slide>
            <swiper-slide class="partner"><img src="../assets/google-logo.svg" /></swiper-slide>
            <swiper-slide class="partner"><img src="../assets/google-logo.svg" /></swiper-slide>
            <swiper-slide class="partner"><img src="../assets/google-logo.svg" /></swiper-slide>
            <swiper-slide class="partner"><img src="../assets/google-logo.svg" /></swiper-slide>
             <swiper-slide class="partner"><img src="../assets/google-logo.svg" /></swiper-slide>
        </swiper>
     
    </div>

    <div class="showcase-section">
        <div class="bg bg-1">
            <img src="../assets/home-imgs/components/backgorund_1.png" />
        </div>
        <div class="big-text">
            <p>Es sind Ihre Daten. Besitzen Sie sie.</p>
        </div>

        <div class="small-text">
            <p>Unsere Technologie bietet Ihnen Transparenz und Kontrolle über die Daten,<br> die Ihnen gehören.</p>
        </div> 

        <div class="rounded-white">
            <div class="row-images">
                <div class="row-images-group">
                    <div class="image">

                        <img src="../assets/home-imgs/screenshots/dashboard_footprint.png" />
                    </div>

                    <div class="small">
                        <img src="../assets/home-imgs/screenshots/dashboard_footprint_small.png" />
                    </div>
                </div>

                <div class="image" v-if="!mobileImg">
                    <img src="../assets/home-imgs/screenshots/dashboard_details.png" />
                </div>
            </div>

            <div class="info-rows">
                <div class="info-row">
                    <div class="info">
                        <div class="number">
                            <img src="../assets/home-imgs/components/one.png" />
                        </div>
                        <p class="text">
                            Entdecken Sie, wo Ihre<br> persönlichen Daten online sind,<br> in weniger als 30 Sekunden.
                        </p>
                        <router-link to="/register" class="default-btn default-started-btn">JETZT STARTEN</router-link>
                    </div>

                    <div class="info">
                        <div class="number">
                            <img src="../assets/home-imgs/components/two.png" />
                        </div>
                        <p class="text">
                            Verstehen Sie schnell Ihre<br> Online-Exposition und<br> vermeiden Sie digitale Risiken.
                        </p>
                        <router-link to="/register" class="default-btn default-started-btn">JETZT STARTEN</router-link>
                    </div>
                </div>

                <div class="info-row info-row3">
                    <div class="info">
                        <div class="number">
                            <img src="../assets/home-imgs/components/three.png" />
                        </div>
                        <p class="text">
                            Seien Sie proaktiv und<br> ergreifen Sie Massnahmen,<br> um unnötige Daten zu<br> entfernen.
                        </p>
                        <router-link to="/register" class="default-btn default-started-btn">JETZT STARTEN</router-link>
                    </div>

                    <div class="info info-image">

                        <div class="image">

                            <img src="../assets/home-imgs/screenshots/dashboard_reclaims.png" />
                        </div>

                        <div class="small">
                            <img src="../assets/home-imgs/screenshots/dashboard_reclaims_small.png" />
                        </div>

                    </div>
                </div>

                <div class="info-row">
                    <div class="info info-image">

                        <div class="image">

                            <img src="../assets/home-imgs/screenshots/dashboard.png" />
                        </div>

                        <div class="small">
                            <img src="../assets/home-imgs/screenshots/dashboard_small.png" />
                        </div>

                    </div>

                    <div class="info">
                        <div class="number">
                            <img src="../assets/home-imgs/components/four.png" />
                        </div>
                        <p class="text">
                            Wenn Ihnen Ihre Freunde und<br> Familie am Herzen liegen,<br> teilen Sie die Botschaft.
                        </p>
                        <router-link to="/register" class="default-btn default-started-btn">JETZT STARTEN</router-link>
                    </div>
                </div>
            </div>

            <div class="shadow-img shadow-img1">
                <img src="../assets/home-imgs/components/shadow_1.png"/>
            </div>

            <div class="shadow-img shadow-img2">
                <img src="../assets/home-imgs/components/shadow_2.png"/>
            </div>
        </div>
    </div>

    <div class="numbers-stats">
        <p class="title">Unsere Zahlen sagen alles</p>
        <div class="numbers-content">
            <div class="number-container">
                <p class="number">100M+</p>
                <p class="text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus, provident nulla. Excepturi ut amet earum.</p>
            </div>
            <div class="number-container">
                <p class="number">54+</p>
                <p class="text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus, provident nulla. Excepturi ut amet earum.</p>
            </div>
            <div class="number-container">
                <p class="number">2,389+</p>
                <p class="text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus, provident nulla. Excepturi ut amet earum.</p>
            </div>
        </div>
    </div>

    <div class="pay-plans">
        <div class="bg bg-2">
            <img src="../assets/home-imgs/components/background_2.png" />
        </div>

        <p class="title">Flexible Pläne, maßgeschneidert für<br> jeden Bedarf</p>
        <p class="subtitle">Erschwingliche Hosting-Lösungen, die Ihnen alles bieten, was Sie<br> brauchen.</p>

        <swiper 
            :slides-per-view="3" 
            :space-between="50" 
            :modules="modules" 
            :pagination="true" 
            :loop="false" 
            class="plans-wrapper"
            :breakpoints=" {  
                982: {
                    slidesPerView: 3 ,
                    spaceBetween: 50 
                },
                801: {
                    slidesPerView: 3,
                    spaceBetween: 15 
                },
                601: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                    loop: true 
                }, 
                541: {
                    slidesPerView: 1.5,
                    spaceBetween: 50, 
                    loop: true 
                },
                0: {
                    slidesPerView: 1,
                    spaceBetween: 100, 
                    loop: true 
                },
            }"    
        >
            <swiper-slide>
                <div class="plan">
                    <div class="title">
                        <p>Starter Plan</p>
                    </div>

                    <div class="plan-attributes">
                        <p class="attribute">10 GB SSD Storage</p>
                        <p class="attribute">Free SSL Certificate</p>
                        <p class="attribute">1 Website</p>
                        <p class="attribute">24/7 Support</p>
                    </div>

                    <a class="default-btn plan-btn">Choose Starter</a>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="plan plan-pro">
                    <div class="title">
                        <p>Pro Plan</p>
                    </div>
                    <div class="plan-attributes">
                        <p class="attribute">50 GB SSD Storage</p>
                        <p class="attribute">Free SSL Certificate</p>
                        <p class="attribute">Unlimited Websites</p>
                        <p class="attribute">Daily Backups</p>
                        <p class="attribute">Priority Support</p>
                    </div>

                    <a class="default-btn plan-btn">Choose Pro</a>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="plan ">
                    <div class="title">
                        <p>Enterprise Plan</p>
                    </div>

                    <div class="plan-attributes">
                        <p class="attribute">Unlimited SSD Storage</p>
                        <p class="attribute">Free SSL Certificate</p>
                        <p class="attribute">Unlimited Websites</p>
                        <p class="attribute">Advanced Security Features</p>
                        <p class="attribute">Dedicated Account Manager</p>
                    </div>

                    <a class="default-btn plan-btn">Choose Enterprise</a>
                </div>
            </swiper-slide>
        </swiper>
    </div>


    <div class="faq-container">
        <p class="title">
            FAQ
        </p>

        <div class="questions-list">
            <div class="question" v-for="question in frequentQuestions" :key="question.id">
                <div class="question-title-wrapper">
                    <h2>{{ question.question }}</h2>
                    <div @click="toggleQuestion(question.id)" class="question-btn">
                        <p v-if="question.isOpen">-</p>
                        <p v-else>+</p>
                    </div>
                </div>
        
                <div class="answ" :class="{ 'answ-active': question.isOpen }" >
                    <p>{{ question.answer }}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="reviews-container">
        <p class="title">
            Erleben Sie die viralste Privatsphäre<br> Erfahrung mit Millionen von Nutzern.
        </p>
        <swiper 
            :slides-per-view="3" 
            :space-between="50" 
            :modules="modules" 
            :pagination="true" 
            :loop="false" 
            class="reviews"
            :breakpoints=" {  
                982: {
                    slidesPerView: 3 ,
                    spaceBetween: 50 
                },
                801: {
                    slidesPerView: 3,
                    spaceBetween: 15 
                },
                601: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                    loop: true 
                },
                0: {
                    slidesPerView: 1,
                    spaceBetween: 50, 
                    loop: true 
                },
            }"      
        >
            <swiper-slide>
                <div class="review">
                    <div class="quote-img">
                        <img src="../assets/home-imgs/reviews/quote.png" />
                    </div>

                    <p class="text">The first one I found in market that gives me a real experience of having an easy payment process</p>

                    <div class="stars"></div>

                    <div class="reviewer">
                        <div class="image">

                        </div>
                        <div class="about">
                            <p class="name">Danny Dario</p>
                            <p class="date">29.08.2024</p>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="review">
                    <div class="quote-img">
                        <img src="../assets/home-imgs/reviews/quote.png" />
                    </div>

                    <p class="text">Making my own card and choosing my own number is a whole new experience for me. Nice work for this super features</p>

                    <div class="stars"></div>

                    <div class="reviewer">
                        <div class="image">

                        </div>
                        <div class="about">
                            <p class="name">Albert Cyrill</p>
                            <p class="date">29.08.2024</p>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="review">
                    <div class="quote-img">
                        <img src="../assets/home-imgs/reviews/quote.png" />
                    </div>

                    <p class="text">I don’t expect it will be ths easy to do transaction even on between different platform. Thanks Payoment!</p>

                    <div class="stars"></div>

                    <div class="reviewer">
                        <div class="image">

                        </div>
                        <div class="about">
                            <p class="name">Madona Cadee</p>
                            <p class="date">29.08.2024</p>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>

        <router-link to="/register" class="default-btn default-started-btn reviews-btn">JETZT STARTEN</router-link>
    </div>

    <footer>
        <div class="footer-wrapper">
            <div class="footer-top">
                <div class="footer-img-wrapper">
                    <div class="footer-img">
                        <img src="../assets/cleandata_logo_white.svg" />
                    </div>
                    <p>
                        The sky was cloudless and of a deep dark<br> blue. The spectacle before us was indeed<br> sublime.
                    </p>
                </div>

                <div class="footer-links footer-menu">
                    <p class="title">MENU</p>

                    <a>About</a>
                    <a>Cases</a>
                    <a>Blog</a>
                    <a>Contact</a>
                </div>

                <div class="footer-links footer-service">
                    <p class="title">SERVICE</p>

                    <a>SEO</a>
                    <a>Marketing</a>
                    <a>SMM</a>
                    <a>Optimisation</a>
                </div>

                <div class="footer-socials">
                    <div class="social instagram">
                        <img src="../assets/home-imgs/social/instagram.png" />
                    </div>
                    <div class="social youtube">
                        <img src="../assets/home-imgs/social/youtube.png" />
                    </div>
                    <div class="social facebook">
                        <img src="../assets/home-imgs/social/facebook.png" />
                    </div>
                    <div class="social linkedin">
                        <img src="../assets/home-imgs/social/linkedin.png" />
                    </div>
                </div>
            </div>

            <div class="footer-bottom">
                <p class="copyright">
                    Copyright © 2025 Adits. All Rights Reserved.
                </p>
                <div class="terms">
                    <a>Terms of Use</a>
                    <a>Privacy Policy</a>
                </div>
            </div>
        </div>
    </footer>
</div>
</template>

<script>
import {
    Swiper,
    SwiperSlide
} from 'swiper/vue';
import { Autoplay , Pagination } from 'swiper/modules';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data(){
      return{
        modules: [Autoplay, Pagination], 
        frequentQuestions:[
          {
            id:'question1',
            question:'Was ist ein Datenmakler??',
            answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat laboriosam provident tenetur laborum illum! Ut temporibus voluptatibus fugit eum minus sapiente, impedit, amet quisquam illo neque autem pariatur eos eligendi?',
            isOpen: false,
          },
          {
            id:'question2',
            question:'How is my personal information online?',
            answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat laboriosam provident tenetur laborum illum! Ut temporibus voluptatibus fugit eum minus sapiente, impedit, amet quisquam illo neque autem pariatur eos eligendi?',
            isOpen: false,
          },
          {
            id:'question3',
            question:'How do I sign up?',
            answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat laboriosam provident tenetur laborum illum! Ut temporibus voluptatibus fugit eum minus sapiente, impedit, amet quisquam illo neque autem pariatur eos eligendi?',
            isOpen: false,
          },
          {
            id:'question4',
            question:'Können Sie Google-Suchergebnisse über mich löschen?',
            answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat laboriosam provident tenetur laborum illum! Ut temporibus voluptatibus fugit eum minus sapiente, impedit, amet quisquam illo neque autem pariatur eos eligendi?',
            isOpen: false,
          },
        ],

        mobMenu: true,
        mobile: false,

        slideMobMenu: false,

        mobileImg: false,
      }
    },    
    methods: {
        toggleQuestion(questionId) {
            this.frequentQuestions = this.frequentQuestions.map(question => ({
                ...question,
                isOpen: question.id === questionId ? !question.isOpen : false
            }));
        }, 

        checkIfMobile(){
            if(window.innerWidth <= 768){
                this.mobile = true;
            }else{
                this.mobile = false;
            }
        },

        checkIfMobileImg(){
            if(window.innerWidth <= 600){
                this.mobileImg = true;
            }else{
                this.mobileImg = false;
            }
        },
        toggleMobileMenu(){
            this.slideMobMenu = !this.slideMobMenu;
            this.mobMenu = !this.mobMenu;
        }
       
    },
    mounted() { 
        this.checkIfMobile();

        this.checkIfMobileImg();
        
        window.addEventListener("resize", this.checkIfMobile);
        window.addEventListener("resize", this.checkIfMobileImg);
    },
    beforeUnmount() { 
        window.removeEventListener("resize", this.checkIfMobile);
        window.removeEventListener("resize", this.checkIfMobileImg);
    },
}
</script>

<style lang="scss" scoped>
nav {
    // width: 1400px;
    // width: 1200px;
    width: 78%;
    margin: 0 auto;
    margin-top: 26px;

    display: flex;
    align-items: center;
    justify-content: space-between;

}

.nav-logo {
    // width: 140px;
    width: 100px;

    img {
        width: 100%;
    }
}

.nav-links {
    display: flex;
    align-items: center;
    gap: 42px;
    // margin-left: 64px;
    // margin-right: 200px;

    a {
        // font-size: 19px;
        font-size: 16px;
        color: #3C3C3C;
        cursor: pointer;
    }
}

.nav-btns {
    display: flex;
    align-items: center;
    gap: 31px;

}

.default-btn {
    display: block;
    width: 186px;
    cursor: pointer;
    border-radius: 20px; 
    padding: 10px 0;
    text-align: center; 
    font-size: 16px;
    transition: 0.3s ease;
    color: white;
    background-color: #FA3A67;
    border: 1px solid #FA3A67;

    &:hover{
        background-color: white;
        color: #FA3A67;
        border: 1px solid #FA3A67;
    }
}

.default-login-btn {
    color: #FA3A67;
    border: 1px solid #FA3A67;
    background-color: white;
    &:hover{
        background-color: #FA3A67;
        color: white; 
    }
}


.get-started-section {
    margin: 0 auto;
    margin-top: 80px;
    margin-bottom: 140px;
    width: 1200px;
    width: 78%;
    display: flex;
    align-items: center;

    .column {
        width: 40%;
    }

    .big-column {
        width: 60%;
    }

    .column-img {
        width: 100%
    }
}

.column-img {
    img {
        width: 100%
    }
}

.big-text {

    p{
        color: #3C3C3C;
    font-size: 38px;
    font-weight: bold;
    }
}

.small-text {
    margin: 20px 0; 
    p{
        font-size: 18px;
        color: #3C3C3C;
    }
}

.partners-section {
    display: flex;
    align-items: center;
    gap: 200px;
    width: 100%;
    padding: 0 55px;
    margin-bottom: 100px;

    .partner {
        width: 165px;

        img {
            width: 100%;
        }
    }
}

.bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    img {
        width: 100%;
    }
}

.showcase-section {
    position: relative;
    width: 100%;

    padding-top: 214px;
    //padding-bottom: 300px;
    //background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 206, 222, 0.8) 50%, rgba(255, 58, 103, 0.8) 100%);

    text-align: center;
    // min-height: 700px;

    .big-text {

        z-index: 2;
        position: relative;
        p{
                    font-size: 42px;
        color: #FAFAFA;
        }
    }

    .small-text {
        p{
            font-size: 18px;
            color: #FAFAFA;
        }
        z-index: 2;
        position: relative;
        margin: 0;
    }
}

.rounded-white {
    position: relative;
    z-index: 2;
    margin-top: 70px;
    z-index: 2;
}

.row-images {
    display: flex;
    align-items: center;
    justify-content: center;

    .row-images-group {
        position: relative;
    }

    .image {
        width: 700px;

        img {
            width: 100%;
        }
    }

}

.small {
    width: 160px;
    position: absolute;
    bottom: 10px;

    img {
        width: 100%;
    }
}

.info-rows {
    padding-top: 70px;
    width: 1200px;
    width: 78%;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

.info-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 180px;

    .info {
        .number {
            width: 45px;

            img {
                width: 100%;
            }
        }

        .text {
            color: #333333;
            font-size: 28px;
            margin-top: 14px;
            margin-bottom: 18px;
            font-weight: bold;
            text-align: left;
        }
    }

    .info-image {
        width: 600px;
        position: relative;

        img {
            width: 100%;
        }

        .small {
            left: -20px;
            bottom: -20px;
        }
    }
}

.shadow-img{
    width: 100%; 
    position: absolute;
    z-index: 1;
    left: 0;
    img{
        width: 100%;
    }
}

.shadow-img1{
    top: 46%; 
}

.shadow-img2{
    top: 78%; 
}

.row-images-group {
    position: relative;
}

.numbers-stats {
    background-color: white;
    padding: 100px 0;
    padding-top: 0;

    .title {
        font-weight: bold;
        color: #3C3C3C;
        font-size: 36px;
        text-align: center;
        margin-bottom: 80px;
    }

    .numbers-content {
        width: 1200px;
        width: 78%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .number-container {
            .number {
                color: #FF3A67;
                font-weight: bold;
                font-size: 36px;
                margin-bottom: 15px;
            }

            .text {
                font-size: 16px;
                font-weight: bold;
                color: #333333;
            }
        }
    }
}

.pay-plans {
    position: relative;

    .title {
        color: #FAFAFA;
        font-weight: bold;
        font-size: 40px;
        text-align: center;
        position: relative;
        z-index: 2;
        padding-top: 170px;
    }

    .subtitle {
        color: #FAFAFA;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-top: 25px;
        position: relative;
        z-index: 2;
    }

    .plans-wrapper {
        position: relative;
        z-index: 2;
        width: 78%;
        padding: 60px 0;
        margin: 100px auto;
        margin-top: 40px;
        display: flex;
        justify-content: space-between;

        .plan {
            // width: 350px;
            width: 90%;
            padding: 20px;
            background-color: white;
            border-top-right-radius: 30px;
            border-bottom-left-radius: 30px;
            box-shadow: 0px 4px 4px #00000040;

            .title {
                padding: 27px 0;
                border: 2px solid #FA3A67;

                border-top-right-radius: 30px;
                border-bottom-left-radius: 30px;

                p {
                    color: #292929;
                    font-size: 24px;
                }
            }

            .plan-attributes {
                margin-top: 40px;
                margin-bottom: 70px;

                .attribute {
                    margin-bottom: 20px;
                    color: #1E1E1E;
                    font-size: 14px;
                }
            }

            .plan-btn {
                border-radius: 0;
                border-top-right-radius: 30px;
                border-bottom-left-radius: 30px;
                margin: 0 auto;
            }

            &-pro {
                transform: scale(1.2);

                .title {
                    p {
                        color: #FA3A67;
                    }
                }
            }
        }

    }

}


.faq-container{
    width: 1200px;
    margin: 0 auto;

    .title{
        color: #3C3C3C;
        font-size: 70px;
        position: relative;
        text-align: center;

        &::after{
            content: "";
            display: block;
            width: 103px;  
            margin: 0 auto;
            height: 4px;  
            border-radius: 5px;
            background-color: #FF3A67;  
        }
    }
}

.questions-list{
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
}

.question{
    box-shadow: 2px 4px 10px #00000029;
    border-radius: 15px;
    margin-bottom: 24px;
}
 
.question-title-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 34px;
}

.question-title-wrapper h2{
    font-size: 24px;
    color: #292C31;
    font-weight: normal
}

.question-btn{
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer; 
}

.question-btn p{
  font-weight: normal;
  font-size: 36px;
  color: #FF3A67;
}

.answ{
  border: none;
  position: relative;
  background-color: var(--body-background-color);
  border-radius: 10px;
  height: 0; 
  overflow: hidden;
  transition: 0.5s ease; 
}

.answ p{
    font-size: 16px;
    width: 95%;
}

.answ-active{
  height: 100px;
  padding: 5px 20px;
}





.reviews-btn {
    margin: 0 auto;
    margin-top: 77px;
}

.reviews-container {
    margin: 90px auto;
    margin-top: 150px;
    width: 78%;

    .title {
        text-align: center;
        color: #333333;
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 77px;
    }

    .reviews {
        width: 100%;

        .review {
            border: 1px solid #CFCFCF;
            padding: 46px 40px;
            width: 100%;

            .quote-img {
                width: 46px;
                margin: 0 auto;
                margin-bottom: 42px;

                img {
                    width: 100%;
                }
            }

            .text {
                color: #3E5073;
                font-size: 18px;
                text-align: center;
                margin-bottom: 87px;
            }

            .stars {
                margin-bottom: 50px;
            }

            .reviewer {
                display: flex;
                align-items: center;
                gap: 10px;

                .image {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: black;
                }

                .about {
                    .name {
                        color: #3E5073;
                        font-size: 16px;
                        font-weight: bold;
                        margin-bottom: 5px;
                    }

                    .date {
                        color: #3E5073;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

footer {
    background-color: #3C3C3C;
    padding: 22px 0;
    // padding-bottom: 150px;

    .footer-wrapper {
        width: 78%;
        margin: 0 auto;

    }

    .footer-top {
        width: 100%;
        justify-content: space-between;
        display: flex;
        margin-bottom: 100px;
    }

    .footer-bottom {
        justify-content: space-between;
        display: flex;
        align-items: center;
        border: 2px solid transparent;
        border-top-color: #464646;
        padding: 40px 25px;
        padding-bottom: 18px;
        color: white;

        p{
            font-size: 18px;
        }

        .terms {
            justify-content: space-between;
            display: flex;
            align-items: center;
            gap: 96px;

            a{
                font-size: 18px;
            }
        }
    }
}

.footer-img-wrapper {
    p {
        font-size: 14px;
        color: white;
        margin-top: 42px;
    }
}

.footer-img {
    width: 120px;

    img {
        width: 100%;
    }
}

.footer-links {
    color: white;
    margin-top: 84px;

    .title {
        font-size: 20px;
        margin-bottom: 35px;
    }

    a {
        display: block;
        margin-bottom: 15px;
    }
}

.footer-socials {
    display: flex;
    align-items: center;
    gap: 35px;

    .social {
        img {
            width: 100%
        }

        width: 20px;
        height: 20px;

    }

    .youtube {
        width: 28px;
        height: 20px;
    }

    .facebook {
        width: 10px;
        height: 20px;
    }
}

.number-container {
        width: 32%;
    }

    .faq-container  {
        width: 65%;
    }

/*my big monitor 1600 - 757 */
@media (min-width: 1600px) {
     
}

/*1640-1080*/
@media(min-width: 1640px) {}

/*1680-1080*/
@media(min-width: 1680px) {}

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {
}

/* 1920-1080 */
@media (min-width: 1920px) {
    .nav-logo{
        width: 140px;
    }
    .nav-links a{
        font-size: 22px;
    }

    .default-btn{ 
        padding: 12px 0; 
        font-size: 19px;
    }

    .get-started-section{
        .big-column{
            .big-text{
                p{
                    font-size: 44px;
                }
            }

            .small-text{
                p{
                    font-size: 20px;
                }
            }
        }
    }

    .showcase-section {
        .big-text p{
            font-size: 48px;
        }

        .small-text{
            p{
                font-size: 20px;
            }
        }
    }

    .info-row .info .text { 
        font-size: 36px;
    }

    .numbers-stats .title { 
        font-size: 48px;
    }

    .numbers-stats .numbers-content .number-container {
        .number  {
            font-size: 52px;
        }
        
        .text  {
        font-size: 20px;}
    }

    .pay-plans {
        .title {
            font-size: 48px;
        }

        .subtitle{
            font-size: 20px;
        }

        .plans-wrapper .plan {
            .title p {
                font-size: 28px;
            }
            .plan-attributes{
                .attribute{
                    font-size: 16px;
                }
            }
        }
    }

    .faq-container .title { 
        font-size: 74px;
    }

    .question-title-wrapper h2 {
        font-size: 26px;
    }

    .reviews-container {
        .title { 
            font-size: 48px;
        }

        .reviews .review{ 
            .text { 
                font-size: 20px;
            }

            .reviewer {
                .image {
                    width: 50px;
                    height: 50px;
                }
                .about {
                    .name  { 
                        font-size: 18px; 
                    }

                    .date{
                        font-size: 16px; 
                    }
                }
            }
        }
    }


    .footer-img-wrapper p {
        font-size: 16px; 
    }

    .footer-img {
        width: 140px;
    }

    .footer-links{
        .title {
            font-size: 24px;
        }

        a{
            font-size: 18px;
        }
    }

    .footer-bottom { 
        p{
            font-size: 20px;
        }

        .terms { 

            a{
                font-size: 20px;
            }
        }
    }
 
}

/* imac 24" */
/* 2048 x 1152 */
@media (min-width: 2048px) {
}

/* 2304-1440 */
@media (min-width: 2304px) {
    .nav-logo {
        width: 160px;
    }
 
    .nav-links {
        gap: 48px;
        a {
            font-size: 25px;
        }
    }

    .nav-btns { 
        gap: 38px;
    }

    .default-btn { 
        font-size: 22px;
        width: 210px;
    }

    .get-started-section .big-column .big-text p {
        font-size: 46px;
    }

    .get-started-section .big-column .small-text p {
        font-size: 22px;
    }

    .showcase-section .big-text p {
        font-size: 52px;
    }

    .showcase-section .small-text p {
        font-size: 24px;
    }

    .info-row .info .number {
        width: 50px;
    }

    .info-row .info .text {
        font-size: 40px;
    }

    .number-container{
        width: 32%;
    }

    .numbers-stats{
        .title {
            font-size: 52px;
        }

        .numbers-content .number-container{
            .number {
                font-size: 56px;
            }

            .text {
                font-size: 22px;
            }
        }
    }


    .pay-plans .title {
        font-size: 52px;
    }

    .pay-plans .subtitle {
        font-size: 24px;
    }

    .pay-plans .plans-wrapper .plan {
        .title p {
            font-size: 32px;
        }

        .plan-attributes .attribute {
            font-size: 18px;
        }
    }
  


    .faq-container .title {
        font-size: 79px;
    }

    .question-title-wrapper h2 {
        font-size: 28px;
    }

    .question-btn {
        height: 30px;
        width: 30px;
        p { 
            font-size: 40px;
        }
    } 

    .answ-active {
        height: 120px;
    }

    .answ p {
        font-size: 20px; 
    }


    .reviews-container {
        .title {
            font-size: 52px;
        }

        .reviews{
            .review {
                .text {
                    font-size: 24px;
                }

                .reviewer{
                    .about{
                        .name {
                            font-size: 22px;
                        }
                        .date {
                            font-size: 20px;
                        }
                    }

                    .image {
                        width: 60px;
                        height: 60px;
                    }
                }
            }
        }
    }


    .footer-img {
        width: 160px;
    }

    .footer-img-wrapper p {
        font-size: 18px;
    }

    .footer-links {
        .title {
            font-size: 26px;
        }

        a {
            font-size: 20px;
        }
    }
 

    .footer-socials {
        .social {
            width: 30px;
            height: 30px;
        }
        .youtube {
            width: 38px;
            height: 30px;
        }
        .facebook {
            width: 14px;
            height: 24px;
        }
    }

    footer .footer-bottom {
        p {
            font-size: 20px;
        }

        .terms a {
            font-size: 20px;
        }
    }
}

/* 2470 - 1328 */
@media (min-width: 2470px) {
}

/*2560-1440*/
@media (min-width: 2560px) {
    .nav-logo {
        width: 180px;
    }
 
    .nav-links {
        gap: 52px;
        a {
            font-size: 28px;
        }
    }

    .nav-btns { 
        gap: 40px;
    }

    .default-btn { 
        font-size: 24px;
        width: 250px;
    }

    .get-started-section .big-column {
        .big-text p {
            font-size: 48px;
        }
        .small-text p {
            font-size: 24px;
        }
    }
 

    .showcase-section {
        .big-text p {
            font-size: 54px;
        }
        .small-text p {
            font-size: 26px;
        }
    } 

    .info-row .info {
        .number {
            width: 55px;
        }
        .text {
            font-size: 40px;
        }
    } 

 

    .numbers-stats{
        .title {
            font-size: 54px;
        }

        .numbers-content .number-container{
            .number {
                font-size: 58px;
            }

            .text {
                font-size: 24px;
            }
        }
    }


    .pay-plans {
        .title {
            font-size: 54px;
        }

        .subtitle {
            font-size: 26px;
        }
    }

    

    .pay-plans .plans-wrapper .plan {
        .title p {
            font-size: 34px;
        }

        .plan-attributes .attribute {
            font-size: 20px;
        }
    }
  
    .faq-container  {
        width: 60%;
        
        .title {
            font-size: 82px;
        }
    }

    .question-title-wrapper h2 {
        font-size: 30px;
    }

    .question-btn {
        height: 35px;
        width: 35px;
        p { 
            font-size: 42px;
        }
    } 

    .answ-active {
        height: 150px;
    }

    .answ p {
        font-size: 22px; 
    }


    .reviews-container {
        .title {
            font-size: 54px;
        }

        .reviews{
            .review {
                .text {
                    font-size: 26px;
                }

                .reviewer{
                    .about{
                        .name {
                            font-size: 24px;
                        }
                        .date {
                            font-size: 22px;
                        }
                    }

                    .image {
                        width: 65px;
                        height: 65px;
                    }
                }
            }
        }
    }


    .footer-img {
        width: 180px;
    }

    .footer-img-wrapper p {
        font-size: 20px;
    }

    .footer-links {
        .title {
            font-size: 28px;
        }

        a {
            font-size: 22px;
        }
    }
 

    .footer-socials {
        .social {
            width: 35px;
            height: 35px;
        }
        .youtube {
            width: 43px;
            height: 35px;
        }
        .facebook {
            width: 19px;
            height: 29px;
        }
    }

    footer .footer-bottom {
        p {
            font-size: 22px;
        }

        .terms a {
            font-size: 22px;
        }
    }
}

/* 2880-1670 */
@media(min-width: 2880px) {
    .info-rows  {
        padding-top: 150px;
    }

    .pay-plans .plans-wrapper {
        padding-top: 100px;
        margin-top: 0px;
    }
}

/* 3000-1870 */
@media(min-width: 3000px) {
    .nav-logo {
        width: 200px;
    }
 
    .nav-links {
        gap: 55px;
        a {
            font-size: 30px;
        }
    }

    .nav-btns { 
        gap: 45px;
    }

    .default-btn { 
        font-size: 26px;
        width: 300px;
    }

    .get-started-section .big-column {
        .big-text p {
            font-size: 50px;
        }
        .small-text p {
            font-size: 26px;
        }
    }
 

    .showcase-section {
        .big-text p {
            font-size: 56px;
        }
        .small-text p {
            font-size: 28px;
        }
    } 

    .info-row .info {
        .number {
            width: 55px;
        }
        .text {
            font-size: 40px;
        }
    } 

    .row-images .image {
        width: 1000px;
    }
 

    .numbers-stats{
        .title {
            font-size: 56px;
        }

        .numbers-content .number-container{
            .number {
                font-size: 60px;
            }

            .text {
                font-size: 26px;
            }
        }
    }


    .pay-plans {
        .title {
            font-size: 56px;
        }

        .subtitle {
            font-size: 28px;
        }

        .plans-wrapper {
            padding-top: 180px;

            .plan {
                .title p {
                    font-size: 36px;
                }

                .plan-attributes .attribute {
                    font-size: 22px;
                }
            }
        }
    } 
 
  
    .faq-container  {
        width: 60%;
        
        .title {
            font-size: 85px;
        }
    }

    .question-title-wrapper h2 {
        font-size: 32px;
    }

    .question-btn {
        height: 38px;
        width: 38px;
        p { 
            font-size: 44px;
        }
    } 

    .answ-active {
        height: 150px;
    }

    .answ p {
        font-size: 24px; 
    }


    .reviews-container {
        .title {
            font-size: 56px;
        }

        .reviews{
            .review {
                .text {
                    font-size: 28px;
                }

                .reviewer{
                    .about{
                        .name {
                            font-size: 26px;
                        }
                        .date {
                            font-size: 24px;
                        }
                    }

                    .image {
                        width: 70px;
                        height: 70px;
                    }
                }
            }
        }
    }


    .footer-img {
        width: 200px;
    }

    .footer-img-wrapper p {
        font-size: 22px;
    }

    .footer-links {
        .title {
            font-size: 30px;
        }

        a {
            font-size: 24px;
        }
    }
 

    .footer-socials {
        .social {
            width: 40px;
            height: 40px;
        }
        .youtube {
            width: 48px;
            height: 40px;
        }
        .facebook {
            width: 24px;
            height: 39px;
        }
    }

    footer .footer-bottom {
        p {
            font-size: 24px;
        }

        .terms a {
            font-size: 24px;
        }
    }
}

/*3200-1800*/
@media(min-width: 3200px) {
}

/*3360-1890 1695*/
@media(min-width:3360px) { 
}
 

/*my small monitor 1536-864*/
@media(max-width:1536px) {}

/* 1440-990 */
@media(max-width:1440px) {
    .nav-links { 
        gap: 38px;
    }

    .nav-btns { 
        gap: 25px;
    }

    .big-text p { 
        font-size: 34px;
    }

    .small-text p {
        font-size: 16px;
    }

    .showcase-section .big-text p {
        font-size: 40px;
    }

    .showcase-section { 
        padding-top: 100px;
    }

    .row-images .image {
        width: 500px;
    }

    .info-row .info {
        .number {
            width: 38px;
        }
        .text { 
            font-size: 24px;
        }
    } 

    .pay-plans {
        .title { 
            padding-top: 120px;
        }

        .plans-wrapper .plan .title {  
            padding: 20px 0;
            p { 
                font-size: 22px;
            }
        }
    } 

    .question-title-wrapper { 
        padding: 26px 34px;
        
        h2 {
            font-size: 20px;
        }
    }
     
}

/*1366-768*/
@media (max-width:1366px) {
}

/* 1280-800 */
@media (max-width:1280px) {
    nav,
    .get-started-section,
    .info-rows,
    .numbers-stats .numbers-content,
    .pay-plans .plans-wrapper,
    .reviews-container,
    footer .footer-wrapper{
        width: 85%;
    }
}

@media (max-width:1200px) {
    .nav-logo {
        width: 90px;
    }

    .nav-links {
        gap: 35px;

        a{
            font-size: 14px;
        }
    }

    .big-text p {
        font-size: 30px;
    }

    .small-text p {
        font-size: 14px;
    }

    .default-btn { 
        width: 160px; 
        font-size: 14px;
    }

    .showcase-section {
        .big-text p {
            font-size: 36px;
        }

        .small-text p {
            font-size: 16px;
        }
    }
    .info-row {
        .info-image {
            width: 450px;
        }
        .info {
            .number {
                width: 30px;
            }
            .text {
                font-size: 20px;
            }
        }
    }


    .numbers-stats {
        .title { 
            font-size: 34px;
        }
        .numbers-content .number-container {
            .number { 
                font-size: 32px; 
            }
            .text {
                font-size: 14px;
            }
        }  
    }

    .pay-plans .title { 
        font-size: 36px;
    }

    .faq-container .title { 
        font-size: 64px;
    }

    .question-title-wrapper {
        padding: 22px 24px;

        h2{
            font-size: 18px;
        }
    }

    .answ p {
        font-size: 14px;
    }

    .reviews-container {
        .title { 
            font-size: 34px;
        }

        .reviews {
            .review { 
                padding: 30px 18px;
                width: 100%;
                
                .text { 
                    font-size: 16px;
                }
                .reviewer {
                    .image {
                        width: 35px;
                        height: 35px;
                    }
                    .about {
                        .name { 
                            font-size: 14px;
                        }
                        .date { 
                            font-size: 12px;
                        }
                    }  
                }
            }
        }
    }

    .footer-img {
        width: 90px;
    }

    .footer-img-wrapper p {
        font-size: 12px;
    }

    .footer-links {
        .title {
            font-size: 18px;
        }
        a{
            font-size: 14px;
        }
    }

    footer .footer-bottom {
        p {
            font-size: 14px;
        }
        .terms a {
            font-size: 14px;
        }
    }

    .footer-socials .youtubev{
        width: 24px;
        height: 16px;
    }
 
}

/*1134x712*/
@media(max-width:1134px) {
    .pay-plans .plans-wrapper { 
        padding: 50px 0; 
        margin-top: 20px;
    }

    .pay-plans .plans-wrapper .plan .title {
        padding: 15px 0;
    }
}

/*1114x705*/
@media(max-width:1114px) {
    .big-text p {
        font-size: 28px;
    }
    
    .showcase-section{    
        padding-top: 70px;
    
        .big-text p {
            font-size: 34px;
        }
    }

    .rounded-white {
        margin-top: 30px;
    }

}

/*1024-768*/
@media (max-width:1024px) {
    .info-row .info-image {
        width: 400px;
    }

    .row-images .image {
        width: 450px;
    }

    .plans-wrapper{
        width: 90%;
    }

    .faq-container .title {
        font-size: 58px;
    }

    .reviews-container .title {
        font-size: 32px;
    }
}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {
    nav, 
    .get-started-section, 
    .info-rows, 
    .numbers-stats 
    .numbers-content, 
    .pay-plans .plans-wrapper, 
    .reviews-container, 
    footer .footer-wrapper {
        width: 90%;
    }

    .pay-plans .title {
        padding-top: 80px;
    }

    .pay-plans .plans-wrapper .plan .title {
        padding: 10px 0;
        p {
            font-size: 20px;
        }
    }

    .footer-socials .youtube {
        width: 24px;
        height: 16px;
    }
}

/* 962x601 */
@media(max-width: 962px) {
    .nav-links {
        gap: 25px;
    }

    .nav-btns {
        gap: 20px;
    }
}

/* 900x1600 */
@media(max-width: 900px) {
    .nav-links {
        gap: 20px;
    }

    .nav-btns {
        gap: 16px;
    }

    .big-text p {
        font-size: 26px;
    }

    .pay-plans .title {
        font-size: 32px;
    }

    .faq-container .title {
        font-size: 50px;
    }

    .reviews-container .title {
        font-size: 30px;
    }
}

/* 884 x 1104 */
@media(max-width: 884px) {
    .faq-container {
        width: 80%;
    }
}

/*834-1112*/
@media(max-width: 834px) {
    .nav-links {
        gap: 14px;
    }
    
    .row-images .image {
        width: 400px;
    }

    .small {
        width: 90px;
    }

}

/* 820x1080 */
@media(max-width: 820px) {}

/* 800x1280 */
@media(max-width: 800px) {
    .get-started-section{
        flex-direction: column-reverse;
        .column {
            width: 50%;
        }
        .big-column {
            width: 100%;
            margin-top: 25px;
        } 
    }  

    .partners-section{
        padding: 0;
    }
 
    .showcase-section {
        padding-top: 40px;
    }
   
    .pay-plans {

        .title {
            padding-top: 50px;
        } 

        .plans-wrapper{
            padding: 10px 0;

            .plan {
                width: 100%;
            }

            .plan-pro {
                transform: scale(1);
            }
        }
    }
  
}

/*778-304*/
@media(max-width: 778px) {}

/* 768x1024 */
@media(max-width: 768px) {
    .footer-links .title {
        font-size: 16px;
    }

    .footer-socials { 
        gap: 25px;
    }

    footer .footer-bottom {
        p {
            font-size: 12px;
        }
        .terms { 
            gap: 40px;
            a {
                font-size: 12px;
            }
        }
    }
    
    .mobile-menu{
        width: 250px;
        background-color: rgba(0, 0, 0, 0.268);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        height: 100%;
        transform: translateX(-250px);
        transition: 0.3s ease;
    }

    .mobile-menu-opened{        
        transform: translateX(0);
    }
   

}

/*712-1138*/
@media(max-width:712px) {
    .showcase-section .big-text p {
        font-size: 30px;
    }

    .row-images .image {
        width: 347px;
    }

    .small {
        width: 80px;
    }

    .info-row .info .text {
        font-size: 18px;
    }

    .numbers-stats .title {
        font-size: 30px;
    }

    .pay-plans{ 
        .title {
            padding-top: 40px;
        }

        .subtitle {
            font-size: 14px;
        }
    }

    .faq-container .title {
        font-size: 40px;
    }

    .question-title-wrapper {
        padding: 18px 14px;
        h2 {
            font-size: 14px;
        }
    }

    .question-btn p { 
        font-size: 28px;
    }

    .answ p {
        font-size: 12px;
    }


    .reviews-container {
        .title {
            font-size: 26px;
        }

        .reviews .review .quote-img {
            width: 36px;
        }
    }
    
    .info-row .info-image {
        width: 347px;

        .small {
            left: -10px;
            bottom: -10px;
            width: 60px; 
        }
    }
 
}

/* 694-768 */
@media(max-width:694px) {
    .showcase-section{
        .big-text p {
            font-size: 26px;
        }
        .small-text p {
            font-size: 14px;
        }
    } 

    .info-row .info .text {
        font-size: 16px;
    }
}

/* 678-1024 */
@media(max-width:678px) {
    footer { 
        padding: 25px 0;
    }

    footer .footer-top { 
        flex-direction: column; 
        margin-bottom: 50px;
    }
 
    .footer-img{
        margin: 0 auto;
    }

    .footer-img-wrapper p {
        font-size: 16px;
        text-align: center; 
        margin-top: 20px; 
    }

    .footer-links{
        text-align: center;
        margin-top: 46px;

        .title {
            font-size: 19px;
            margin-bottom: 30px;
        }

        a {
            font-size: 17px;
        }
    }

    .footer-socials{
        margin-top: 40px;  
        justify-content: center;
        gap: 35px;
    }

    .footer-socials .youtube {
        width: 28px;
        height: 20px;
    }
 

    footer .footer-bottom {
        flex-direction: column;

        p {
            font-size: 16px;
        }

        .terms {
            gap: 80px;
            a {
                font-size: 16px;
            }
        }
    }

    .copyright{
        margin-bottom: 30px;
        width: 190px;
        text-align: center;
    }

}

/* 600x962 */
@media(max-width:600px) {
    .get-started-section{
        margin-bottom: 60px;
    }

    .big-text p,
    .small-text p{
        text-align: center;
    }

    .get-started-section .big-column .default-started-btn{
        margin: 0 auto;
    }

    .partners-section { 
        margin-bottom: 0px;
    }


    .showcase-section .big-text p {
        font-size: 24px;
    }

    .rounded-white {
        margin-top: 10px;
    }

    .row-images .image {
        width: 365px;
    }

    .info-rows {
        padding-top: 40px;
    }

    .info-row{
        flex-direction: column;
        gap: 60px;
        margin-bottom: 60px;

        .info{
            .number{
                margin: 0 auto;
                width: 38px;
            }

            .text {
                font-size: 24px;
                text-align: center;
            }

            .default-btn{
                margin: 0 auto;
            }
        }

        .info-image{
            margin: 0 auto;
            width: 366px;
        }
    }

    .info-row3{
        flex-direction: column-reverse;
    }

    .numbers-stats {
        padding-bottom: 60px;

        .title {
            font-size: 24px;
            margin-bottom: 60px;
        }

        .numbers-content{
            flex-direction: column;
            gap:40px;

            .number-container{
                width: 60%;
                .number{
                    text-align: center;
                    font-size: 34px;
                }
                .text{
                    text-align: center;
                }
            }
        }
    }

    .pay-plans{ 
        margin-bottom: 60px;
        
        .title {
            font-size: 24px;
        }

        .subtitle {
            margin-top: 15px;
        }

        .plans-wrapper { 
            margin-top: 10px;
        }

        .swiper{
            margin-bottom: 0;
        }
    }

 
    .faq-container {
        width: 90%;
        .title {
            font-size: 34px;
        }
    }

    .question-title-wrapper {
        padding: 14px 10px;
    }

    .answ-active{
        padding: 5px 10px;
    }

    .reviews-container{
        margin : 60px auto;
        .title {
            font-size: 24px;
            margin-bottom: 30px;
        }

        .reviews .review {
            width: 85%;
            margin: 0 auto;
            padding: 40px 35px; 
            .quote-img {
                width: 40px;
                margin-bottom: 26px;
            }
            .text {  
                margin-bottom: 60px;
            }
            .reviewer{
                justify-content: center;
            }
        }

        .reviews-btn{
            margin-top: 10px;
        } 
    }

   
    .default-btn{
        width: 176px;
    }
}



/* 577-951 */
@media(max-width:577px) {}

/* 540-960 */
@media(max-width:540px) {
    .pay-plans .plans-wrapper .plan {
        width: 75%;
        margin: 0 auto;
    }
}

/*480-800*/
@media (max-width:480px) {
    .faq-container {
        width: 90%;
    }

    nav, .get-started-section, .info-rows, .numbers-stats .numbers-content, .pay-plans .plans-wrapper, .reviews-container, footer .footer-wrapper {
        width: 95%;
    }

    .get-started-section{
        margin-top: 60px;

        .column {
            width: 60%;
        }

        .big-column{
            width: 100% !important;
        }
    }

    .big-text p {
        font-size: 24px;
    }

    .small-text p {
        font-size: 12px;
    }

    .pay-plans .title {
        font-size: 20px;
    }

    .pay-plans .subtitle {
        font-size: 12px;
        margin-top: 8px;
    }

    .reviews-container .title {
        font-size: 22px;
    }

    .info-row .info .text {
        font-size: 22px;
    }

    .showcase-section .small-text p {
        font-size: 12px;
    }

    // .bg-2 {
    //     width: 150% !important;
    // }
}

/*438-1024*/
@media(max-width:438px) {
}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px){
    .numbers-stats .numbers-content .number-container {
        width: 75%;
    }
}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px){
    .showcase-section {
        padding-top: 10px;
    }

    .pay-plans .title {
        padding-top: 20px;
    }
}

/*414-736 617   */
@media (max-width:414px) {
}

/*390 x 844*/
@media(max-width:393px) {
}

/*384-640*/
@media(max-width:384px) {
}

/*375*/
@media(max-width:375px) {
    .row-images .image[data-v-fae5bece] {
        width: 100%;
    }

    .info-row .info-image[data-v-fae5bece] { 
        width: 100%;
    }
}

/*360x640*/
@media(max-width:360px) {
}

/*320x568*/
@media(max-width:320px) {
}
</style>
